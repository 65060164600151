<template>
   <div class="mainform">
        <!-- 物料凭证 -->
        <div class="form">
                <el-form  class="content bascform" :model="form" :disabled="true"  label-width="120px">
                    <div  class="col col4">
                        <el-form-item label="物料凭证号" >
                            <el-input v-model="form.materialVoucherNo"></el-input>
                        </el-form-item>
                    </div>
                    <div  class="col col4">
                        <el-form-item label="凭证行号" >
                            <el-input v-model="form.voucherBankNumber"></el-input>
                        </el-form-item>
                    </div>
                    <div  class="col col4">
                        <el-form-item label="事务类型" >
                            <span class="onlyText">
                                {{form.transactionType == '0'?'收货' :'退货'}}
                            </span>
                        </el-form-item>
                    </div>
                    <div  class="col col4">
                        <el-form-item label="收货数量">
                            <el-input v-model="form.quantityReceived"></el-input>
                        </el-form-item>
                    </div>
                    <div  class="col col4">
                        <el-form-item label="过账日期" >
                            <el-input v-model="form.postingDate"></el-input>
                        </el-form-item>
                    </div>
                    <div  class="col col4">
                        <el-form-item label="移动类型" >
                            <el-input v-model="form.mobileType"></el-input>
                        </el-form-item>
                    </div>
                    <div class="col col4">
                        <el-form-item label="外协供应商">
                            <el-input v-model="form.outsourcingSupplier"></el-input>
                        </el-form-item>
                    </div>
                    <div  class="col col4">
                        <el-form-item label="免费标识" >
                            <el-input v-model="form.freeLogo"></el-input>
                        </el-form-item>
                    </div>
                    <div  class="col col4">
                        <el-form-item label="采购描述" >
                            <el-input v-model="form.purchaseDescription"></el-input>
                        </el-form-item>
                    </div>
                </el-form>
        </div>
    </div>
</template>

<script>
export default {
  name: 'StatementSeeDetailDialog',
  props: ['showData'],
  data () {
    return {
      form: this.showData
    }
  }

}
</script>
<style scoped lang="scss">
  @import '@/assets/css/form.scss';
  @import '@/assets/css/elForm.scss';
</style>
